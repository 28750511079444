html {
  height: -webkit-fill-available;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #7fa56ccf !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.estimate-holder {
  display: flex;
  max-width: 70%;
  padding: 1em;
  flex-direction: column;
  border-radius: 4px;
}

.estimate-header {
  display: flex;
  flex-direction: row;
  padding: 1em;
  font-weight: 500;
  font-size: 24px;
  background-color: white;
  padding-bottom: 0.5em;
}

.estimate-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: -webkit-fill-available;
  padding-bottom: 0.5em;
  border-right: 2px solid #7fa56ccf;
  border-bottom: 2px solid #7fa56ccf;
}

.estimate-header span {
  text-align: center;
}

.customer-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: -webkit-fill-available;
  padding-bottom: 0.5em;
  font-size: 24px;
  border-bottom: 2px solid #7fa56ccf;
}

.customer-body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 24px;
}

.estimate-body {
  background-color: white;
  padding: 1em;
  font-size: 24px;
  display: flex;
  flex-direction: column;
}

.estimate-body-title {
  padding: 1em;
  padding-top: 0.5em;
  font-weight: 600;
  font-size: 24px;
}

.estimate-desc {
  border-top: 2px solid #7fa56ccf;
  border-bottom: 2px solid #7fa56ccf;
  padding: 1em;
  padding-bottom: 0.5em;
}

.estimate-footer {
  background-color: white;
  padding: 1em;
  font-size: 22px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.estimate-amount {
  display: flex;
  padding: 1em;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  border-bottom: 4px solid #7fa56ccf;
  border-top: 4px solid #7fa56ccf;
  color: rgb(0, 0, 0);
  text-transform: uppercase;
  font-weight: 500;
}

.estimate-form {
  display: flex;
  padding: 1em 0px;
  justify-content: space-between;
}

.estimate-logo {
  width: 150px;
  height: 150px;
}
.estimate-header-title {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 1em;
}

.accept-modal {
  display: flex;
  justify-content: center;
  padding: 1em;
}

.accept-span {
  font-size: 24px;
  display: flex;
  align-items: center;
}

.modal-dialog {
  height: -webkit-fill-available;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 768px) {
  .estimate-holder {
    width: 100%;
    height: 70%;
  }

  .estimate-header {
    flex-direction: column;
    border-bottom: 2px solid #7fa56ccf;
  }

  .estimate-info {
    border: none;
    border-bottom: 2px solid #7fa56ccf;
    padding-bottom: 1em;
  }

  .customer-info {
    border: none;
    padding-top: 1em;
  }

  .estimate-body {
    border-bottom: 2px solid #7fa56ccf;
  }
}
